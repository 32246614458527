import { sum } from 'lodash'
import { LatLng } from './MapDataDrawer.custom'

export const findCenter1 = (locations: LatLng[]): LatLng => {
  const lats = locations.map((m) => m.latitude)
  const lngs = locations.map((m) => m.longitude)
  return {
    latitude: sum(lats) / lats.length,
    longitude: sum(lngs) / lngs.length,
  }
}

/**
 * Not sure it works correctly
 * README: https://stackoverflow.com/questions/68886098/how-can-i-get-the-center-coordinate-from-the-given-coordinates
 */
export const findCenter = (locations: LatLng[]): Partial<LatLng> => {
  const getMiddle = (prop: keyof LatLng, locations: LatLng[]) => {
    const isLongitude = prop === 'longitude'
    let values = locations.map((m) => {
      if (isLongitude && m[prop] < 0) {
        return m[prop] + 360
      }
      return m[prop]
    })
    let result = sum(values) / values.length
    if (isLongitude && result > 180) {
      result -= 360
    }
    return result
  }

  const answer = {
    latitude: getMiddle('latitude', locations),
    longitude: getMiddle('longitude', locations),
  }

  return answer
}
